export const removeImageSlash = () => {
  try {
    return this.replace(/\\/g, '/');
  } catch (error) {
    return '';
  }
};

export const CommaSeparator = (number) => {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const phoneNumberFormat = (number) => {
  try {
    if (number.startsWith('+964')) {
      return number.replace('+964', '0');
    } else {
      return number;
    }
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const phoneNumberFormatHiddenThreeDigits = (number) => {
  try {
    let newNumber;

    if (number.startsWith('+964')) {
      newNumber = number.replace('+964', '0');
    } else {
      newNumber = number;
    }

    const pattern = /(\d{3})(\d{4})$/;

    newNumber = newNumber.replace(pattern, 'xxx$2');

    return newNumber;
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const formatSpacePhoneNumber = (number) => {
  return number?.replace(/(\+964)(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
};
