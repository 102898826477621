import { CircularProgress, Dialog, Drawer } from '@mui/material';
import React from 'react';
import ListingDetail from './ListingDetail';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';

const ListingDetailDialog = () => {
  const { isListingDialogOpen } = useGlobal();

  return (
    <Drawer
      anchor='right'
      open={isListingDialogOpen}
      sx={{
        zIndex: 1200,
        height: 'auto',
        overflow: 'hidden', // Hide scrollbars
        '& .MuiDialog-paper': {
          overflow: 'hidden', // Ensure no overflow in the Dialog paper
        },
      }}
    >
      <ListingDetail />
    </Drawer>
  );
};

export default ListingDetailDialog;
