// export const UserHasPermission = (
//   permitedPermissionKey,
//   userPermissions,
//   requireSuperAdmin = false,
// ) => {
//   if (userPermissions?.IsSuperAdmin) {
//     return true;
//   }

//   if (requireSuperAdmin == true) {
//     return false;
//   }
//   if (permitedPermissionKey && permitedPermissionKey != '') {
//     if (userPermissions?.PermissionKeys?.includes(permitedPermissionKey)) {
//       return true;
//     }
//     return false;
//   }
//   return true;
// };

export const UserHasPermission = (permitedPermissionKey, userPermissions, requireSuperAdmin = false) => {
  if (userPermissions?.IsSuperAdmin) {
    return true;
  }

  if (requireSuperAdmin === true) {
    return false;
  }

  // If permitedPermissionKey is an array, check if user has any of the permissions
  if (Array.isArray(permitedPermissionKey)) {
    return permitedPermissionKey.some((key) => userPermissions?.PermissionKeys?.includes(key));
  }

  // Original single permission check
  if (permitedPermissionKey && permitedPermissionKey !== '') {
    return userPermissions?.PermissionKeys?.includes(permitedPermissionKey);
  }

  return true;
};
