import React from 'react';
import PropTypes from 'prop-types';
import { TicketSourceOperatorTypes } from '@crema/constants/AppEnums';
import { Box, Tooltip, Typography } from '@mui/material';
const TicketLogCreatorTypeBubble = ({ ticketLog }) => {
  return (
    <>
      {ticketLog?.LogCreatorType == TicketSourceOperatorTypes.SystemAdmin && (
        <Tooltip title={ticketLog?.CreatedByApplicationUser?.FullName} arrow>
          <Box
            sx={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              background: '#074799',
              color: '#fff',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
            }}
          >
            {ticketLog?.CreatedByApplicationUser?.FullName.split(' ')
              .map((name) => name[0].toUpperCase())
              .slice(0, 2)
              .join('')}
          </Box>
        </Tooltip>
      )}
      {ticketLog?.LogCreatorType == TicketSourceOperatorTypes.AppUser && (
        <Box
          sx={{
            width: 'fit-content',
            minWidth: 40,
            borderRadius: '20px',
            background: '#E84545',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: 12,
            px: 2,
            py: 1,
          }}
        >
          App User
        </Box>
      )}
      {ticketLog?.LogCreatorType == TicketSourceOperatorTypes.System && (
        <Box
          sx={{
            width: 'fit-content',
            minWidth: 40,
            borderRadius: '20px',
            background: '#222B36',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: 12,
            px: 2,
            py: 1,
          }}
        >
          System
        </Box>
      )}
    </>
  );
};

export default TicketLogCreatorTypeBubble;

TicketLogCreatorTypeBubble.propTypes = {
  ticketLog: PropTypes.object,
};
