import CustomNotification from '@crema/components/CustomComponents/CustomNotification';
import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import SystemHubConnection from '@crema/services/Hub/SystemHubConnection';
import ReceiveNewCallCardDialog from '@crema/components/CustomComponents/ReceiveNewCallCardDialog';
import InboundCallUserDetailsDialog from '@crema/components/CustomComponents/InboundCallUserDetails/InboundCallUserDetailsDialog';
import ManualCallCardDialog from '@crema/components/CustomComponents/ManualCallCardDialog';
import AppLoader from '@crema/components/AppLoader';
import { PermissionKeys, PrivateAdTableType } from '@crema/constants/AppEnums';
import { uuid } from 'uuidv4';
import ListingDetailDialog from '@crema/components/CustomComponents/ListingDetailDialog/ListingDetailDialog';
import LockedDialog from '@crema/components/CustomComponents/ListingDetailDialog/LockedDialog';
import AddCreditDialog from '@crema/components/CustomComponents/AddCreditDialog';
import { UserHasPermission } from '@crema/helpers/RouteHelper';
import { useUserPermissions } from './PermissionContextProvider';

const GlobalContext = createContext();

export const useGlobal = () => {
  return useContext(GlobalContext);
};

export const GlobalContextProvider = ({ children }) => {
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const { userPermissions } = useUserPermissions();
  const { user } = useAuthUser();

  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);

  const [isListingDialogOpen, setIsListingDialogOpen] = useState(false);

  const [listingDetails, setListingDetails] = useState();

  const [lockedDialogOpen, setLockedDialogOpen] = useState(false);
  const [lockListingID, setLockListingID] = useState();
  const lockListingIDRef = useRef(lockListingID);

  const [lockTokenValue, setLockeTokenValue] = useState();
  const [lockListingData, setLockListingData] = useState();
  const [lockedListingDetail, setLockedListingDetail] = useState();

  const [listingConfigurations, setListingConfigurations] = useState([]);

  const [showroomsList, setShowroomsList] = useState([]);

  const [outboundCallCardSubjectTypesList, setOutboundCallCardSubjectTypesList] = useState([]);
  const [packageTicketUpgradeValues, setPackageTicketUpgradeValues] = useState([]);
  const [selectedCallDictionaryKey, setSelectedCallDictionaryKey] = useState();

  const [closePackageUpgradeTicketDialog, setClosePackageUpgradeTicketDialog] = useState(false);
  const [ticketDetails, setTicketDetails] = useState();

  const [manualCreditPaymentTypeList, setManualCreditPaymentTypeList] = useState([]);

  const [inboundCallCardSubjectTypesList, setInboundCallCardSubjectTypesList] = useState([]);

  const [openManualCallCardDialog, setOpenManualCallCardDialog] = useState(false);

  const [openAddCreditDialog, setOpenAddCreditDialog] = useState(false);

  const [openReceiveNewCallCardDialog, setOpenReceiveNewCallCardDialog] = useState(false);

  const [openInboundCallUserDetailsDialog, setOpenInboundCallUserDetailsDialog] = useState(false);

  const [listingAndUserInfo, setlistingAndUserUserInfo] = useState();
  const [receiveNewCallCard, setRecieveNewCallCard] = useState();
  const [inboundCallUserDetails, setInboundCallUserDetails] = useState();

  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupAssignmentsWithUsers, setGroupAssignmentsWithUsers] = useState([]);

  const generateLockTokenValue = () => {
    // Convert UUID to byte array
    const bytes = [];
    for (let i = 0; i < uuid.length; i += 2) {
      bytes.push(parseInt(uuid.substr(i, 2), 16));
    }

    // Resize array to 17 bytes (pad with zeros if necessary)
    while (bytes.length < 17) {
      bytes.push(0);
    }
    if (bytes.length > 17) {
      bytes.length = 17;
    }

    // Convert byte array to BigInt
    let bigInt = BigInt(0);
    for (let i = 0; i < bytes.length; i++) {
      bigInt = (bigInt << BigInt(8)) + BigInt(bytes[i]);
    }

    // Generate a random number between 0 and 999
    const randomNum = Math.floor(Math.random() * 1000);

    // Concatenate BigInt and random number to get the final value
    const finalValue = bigInt.toString() + randomNum;

    // Set the value in state
    setLockeTokenValue(finalValue);
  };

  const getUsersList = () => {
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/Ticket/user-list-for-assigned-ticket-groups`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setUsers(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getGroupAssignments = () => {
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/Ticket/ticket-assignment-groups`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setGroups(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGroupAssignmentsWithUsers = () => {
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/Ticket/ticket-assignment-groups-with-users`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setGroupAssignmentsWithUsers(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    generateLockTokenValue();
  }, []);

  useEffect(() => {
    lockListingIDRef.current = lockListingID;
  }, [lockListingID]);

  const handleCardClick = async (listing) => {
    if (!UserHasPermission(PermissionKeys.CRMListingsPermissionGroup.ViewCRMListingsDetails, userPermissions)) {
      showSnackbar('Access Denied', 'error');
      return;
    }
    setIsLoadingGlobal(true);
    await lockListing(listing);
  };

  const lockListing = async (listing, override = false) => {
    let requestUrl;
    if (listing?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      requestUrl = `${process.env.React_App_Base_URL}/api/CRMPendingListing/lock-draft`;
    } else if (listing?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      requestUrl = `${process.env.React_App_Base_URL}/api/CRMPendingListing/lock-car`;
    }

    const settings = {
      method: 'post',
      url: requestUrl,
      timeout: 0,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
      data: JSON.stringify({
        Items: [{ ID: listing.ID, SaveDate: listing.SaveDate }],
        LockToken: lockTokenValue,
        LockMode: 1,
        Overwrite: override,
      }),
    };

    try {
      const response = await axios(settings);

      const lockListingResponse = response.data[listing.ID];

      if (lockListingResponse.LockStatus === 0) {
        setLockListingID(listing.ID);
        setLockListingData(null);
        setLockedDialogOpen(false);
        openListingDialog(listing.ID, listing.PrivateAdTableTypeId);
        await loopLock(listing);
        return false;
      } else if (lockListingResponse.LockStatus === 1) {
        setLockListingID(listing.ID);
        setIsListingDialogOpen(false);
        setLockListingData(lockListingResponse);
        setLockedListingDetail(listing);
        setLockedDialogOpen(true);
        setIsLoadingGlobal(false);
        return true;
      } else if (lockListingResponse.LockStatus === 2) {
        window.location.reload();

        return false;
      }
    } catch (error) {
      setIsLoadingGlobal(false);

      console.error(error);
      return true; // Assume locked on error
    }
  };

  const loopLock = async (listing) => {
    let requestUrl;
    if (listing?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      requestUrl = `${process.env.React_App_Base_URL}/api/CRMPendingListing/lock-draft`;
    } else if (listing?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      requestUrl = `${process.env.React_App_Base_URL}/api/CRMPendingListing/lock-car`;
    }

    const settings = {
      method: 'post',
      url: requestUrl,
      timeout: 0,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
      data: JSON.stringify({
        Items: [{ ID: listing.ID, SaveDate: listing.SaveDate }],
        LockToken: lockTokenValue,
        LockMode: 1,
        Overwrite: false,
      }),
    };

    try {
      const response = await axios(settings);
      const lockListingResponse = response.data[listing.ID];
      setIsLoadingGlobal(false);

      if (lockListingResponse.LockStatus === 0) {
        // setLockListingID(null);

        setLockListingData(null);
        setLockedDialogOpen(false);
        await sleep(10000);

        if (lockListingIDRef.current === listing.ID) {
          await loopLock(listing);
        }
      } else if (lockListingResponse.LockStatus === 1) {
        setLockListingID(null);
        setLockListingData(lockListingResponse);
        setLockedListingDetail(listing);
        setLockedDialogOpen(true);
        setIsListingDialogOpen(false);

        await sleep(10000);
        if (lockListingIDRef.current === listing.ID) {
          await loopLock(listing);
        }
      } else if (lockListingResponse.LockStatus === 2) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      setIsLoadingGlobal(false);
    }
  };

  const handleOverride = (listing) => {
    lockListing(listing, true);
  };

  const openListingDialog = (listingID, PrivateAdTableTypeId) => {
    let url;
    if (PrivateAdTableTypeId == PrivateAdTableType.FromDraftTable) {
      url = `${process.env.React_App_Base_URL}/api/CRMPendingListing/draft-details`;
    } else if (PrivateAdTableTypeId == PrivateAdTableType.FromCarTable) {
      url = `${process.env.React_App_Base_URL}/api/CRMPendingListing/listing-details`;
    }

    const config = {
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
      params: {
        Id: listingID,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setIsListingDialogOpen(true);
          setListingDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingGlobal(false);
      });
  };

  const closeListingDialog = () => {
    setIsListingDialogOpen(false);
    setLockListingID(null);
    setLockListingData(null);
  };

  //SignalR Connection
  useEffect(() => {
    if (user?.Token) {
      const conn = SystemHubConnection();

      conn.on('OnReceiveNewCallCard', (ReceiveNewCallCard) => {
        setRecieveNewCallCard(ReceiveNewCallCard);
        setOpenReceiveNewCallCardDialog(true);

        //Dialog show call card
      });

      conn.on('OnInboundCallAnswered', (InboundCallAnswered) => {
        setInboundCallUserDetails(InboundCallAnswered);
        setOpenInboundCallUserDetailsDialog(true);

        //Drawer show user info and lsiting history and calls history
      });

      conn.on('OnCallSave', (callID) => {
        setOpenReceiveNewCallCardDialog(false);

        //Drawer show user info and lsiting history and calls history
      });

      return () => {
        conn.stop();
      };
    }
  }, []);

  const getListingConfiguration = () => {
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/Home/listing-configuration`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.Token,
      },
    };

    axios(config)
      .then((response) => {
        let configurations = response.data.data;

        setListingConfigurations(configurations);

        setOutboundCallCardSubjectTypesList(configurations.OutboundCallCardSubjectTypesDict);
        setPackageTicketUpgradeValues(configurations.PackageTicketUpgradeValues);

        setManualCreditPaymentTypeList(configurations.ManualCreditPaymentTypeList);

        setInboundCallCardSubjectTypesList(configurations.InboundCallCardSubjectTypesList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCompletePackageUpgradeTicket = (ticketID, packageTicketUpgradeValue, assignToMeOnly, taskStartDate) => {
    const config = {
      method: 'post',
      url: `${process.env.React_App_Base_URL}/api/Ticket/complete-package-upgrade-ticket/${ticketID}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.Token,
      },
      data: {
        packageTicketUpgradeValue,
        assignToMeOnly,
        taskStartDate,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setClosePackageUpgradeTicketDialog(true);
          showSnackbar('Package has been upgraded', 'success');
        } else {
          showSnackbar('Failed to complete ticket', 'error');
        }
      })
      .catch(function (error) {
        console.log(error);
        showSnackbar('Failed to complete ticket', 'error');
      });
  };

  const getShowroomsList = () => {
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/Home/showrooms-list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.Token,
      },
    };

    axios(config)
      .then((response) => {
        let configurations = response.data.data;

        setShowroomsList(configurations);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getListingConfiguration();
    getShowroomsList();
    getUsersList();
    getGroupAssignments();
    getGroupAssignmentsWithUsers();
  }, []);

  const [snackbar, setSnackbar] = useState({
    message: '',
    type: 'info',
    open: false,
  });

  const showSnackbar = useCallback((message, type = 'info') => {
    setSnackbar({ message, type, open: true });
  }, []);

  const hideSnackbar = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const [reloadCRMBoards, setReloadCRMBoards] = useState(false);

  const triggerCRMBoardReload = () => {
    setReloadCRMBoards((prev) => !prev);
  };

  return (
    <GlobalContext.Provider
      value={{
        showSnackbar,
        hideSnackbar,
        listingConfigurations,
        showroomsList,
        outboundCallCardSubjectTypesList,
        packageTicketUpgradeValues,
        closePackageUpgradeTicketDialog,
        setClosePackageUpgradeTicketDialog,
        handleCompletePackageUpgradeTicket,
        ticketDetails,
        setTicketDetails,
        selectedCallDictionaryKey,
        setSelectedCallDictionaryKey,
        manualCreditPaymentTypeList,
        inboundCallCardSubjectTypesList,
        openManualCallCardDialog,
        setOpenManualCallCardDialog,
        openAddCreditDialog,
        setOpenAddCreditDialog,
        openReceiveNewCallCardDialog,
        setOpenReceiveNewCallCardDialog,
        listingAndUserInfo,
        setlistingAndUserUserInfo,
        receiveNewCallCard,
        openInboundCallUserDetailsDialog,
        setOpenInboundCallUserDetailsDialog,
        inboundCallUserDetails,
        isLoadingGlobal,
        setIsLoadingGlobal,
        handleCardClick,
        handleOverride,
        isListingDialogOpen,
        setIsListingDialogOpen,
        closeListingDialog,
        listingDetails,
        setListingDetails,

        setLockListingID,
        lockTokenValue,
        lockListingID,
        lockListingIDRef,
        setLockedDialogOpen,
        lockedDialogOpen,
        setLockListingData,
        lockListingData,
        setLockedListingDetail,
        lockedListingDetail,
        lockListing,
        snackbar,
        openListingDialog,
        triggerCRMBoardReload,
        reloadCRMBoards,
        users,
        groups,
        groupAssignmentsWithUsers,
        getUsersList,
        getGroupAssignments,
      }}
    >
      {children}

      <CustomNotification message={snackbar?.message} type={snackbar?.type} status={snackbar?.open} />
      {openManualCallCardDialog && <ManualCallCardDialog />}
      {openAddCreditDialog && <AddCreditDialog />}
      {openReceiveNewCallCardDialog && <ReceiveNewCallCardDialog />}

      {openInboundCallUserDetailsDialog && <InboundCallUserDetailsDialog />}

      <ListingDetailDialog />
      <LockedDialog />
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
