import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { IoMdClose } from 'react-icons/io';
import PropTypes from 'prop-types';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import { IoLockClosedSharp } from 'react-icons/io5';
import { useUserPermissions } from '@crema/context/AppContextProvider/PermissionContextProvider';
import { UserHasPermission } from '@crema/helpers/RouteHelper';
import { PermissionKeys } from '@crema/constants/AppEnums';

const LockedDialogComponent = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
    width: '500px',
    height: '150px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const LockedDialog = () => {
  const { lockListingData, lockedDialogOpen, setLockedDialogOpen, lockedListingDetail, handleOverride } = useGlobal();

  return (
    <LockedDialogComponent
      onClose={() => setLockedDialogOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={lockedDialogOpen}
      aria-hidden={!lockedDialogOpen}
    >
      <DialogTitle sx={{ m: 0, p: 3 }} id='customized-dialog-title'>
        <Typography variant='h3'>Locked Listing</Typography>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setLockedDialogOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          color: 'grey',
        }}
      >
        <IoMdClose />
      </IconButton>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Box
            sx={{
              background: '#ffab0029',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IoLockClosedSharp style={{ color: '#b76e00', width: '30px', height: '30px' }} />
          </Box>

          <Typography variant='h4' sx={{ mt: 3, fontWeight: '500' }}>
            Locked Listing By: {lockListingData?.LockedBy}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            p: 2,
          }}
        >
          <Button
            onClick={() => setLockedDialogOpen(false)}
            type='submit'
            sx={{
              px: 1,
              background: '#fff',
              border: '0.5px solid #ccc',
              color: '#31304D',
            }}
          >
            Close
          </Button>
          {lockListingData?.AllowOverwrite && (
            <Button
              onClick={() => handleOverride(lockedListingDetail)}
              sx={{
                width: '120px',
                background: '#222B36',
                color: '#fff',
                '&:hover': {
                  background: '#454F5B',
                  '& svg': {
                    color: '#fff',
                  },
                },
              }}
            >
              Unlock Listing
            </Button>
          )}
        </Box>
      </DialogActions>
    </LockedDialogComponent>
  );
};

export default LockedDialog;
