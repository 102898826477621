import React from 'react';
import { Box } from '@mui/material';

const AppLogo = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        background: '#fff',
        padding: '4px 8px',
        borderRadius: '5px',
      }}
      className='app-logo'
    >
      <img src='/assets/images/iqcars.png' style={{ width: '150px' }} />
    </Box>
  );
};

export default AppLogo;
