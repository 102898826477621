import { useAuth, useAuthActions } from '@crema/services/auth/CustomAuthProvider';

export const useAuthUser = () => {
  // const { user, isAuthenticated, isLoading } = useAuth();

  const userData = localStorage.getItem('userInfo');
  const user = JSON.parse(userData);
  return {
    isLoading: false,
    isAuthenticated: user?.Token ? true : false,
    user: user,
  };
};

export const useAuthMethod = () => {
  const { logInWithCustomEmailAndPassword, customLogout } = useAuthActions();

  return {
    logInWithCustomEmailAndPassword,
    customLogout,
  };
};
