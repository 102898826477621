import enLang from './entries/en-US';
import arLang from './entries/ar_IQ';
import kuLang from './entries/ku_DZ';

const AppLocale = {
  en: enLang,
  ar: arLang,
  ku: kuLang,
};

export default AppLocale;
