import { useAuthUser } from '@crema/hooks/AuthHooks';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// Initialize Firebase

const firebaseConfig = {
  apiKey: 'AIzaSyCOXXmsFHu8lRoqJUPTQjlCNgikli3x9nc',
  authDomain: 'iqcars-c3d7f.firebaseapp.com',
  databaseURL: 'https://iqcars-c3d7f.firebaseio.com',
  projectId: 'iqcars-c3d7f',
  storageBucket: 'iqcars-c3d7f.appspot.com',
  messagingSenderId: '163752575765',
  appId: '1:163752575765:web:29abff52d2fafdd8605f1c',
  measurementId: 'G-183SCMR33J',
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const { user } = useAuthUser();

function iOS() {
  if (
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/Macintosh/i) ||
    navigator.userAgent.match(/Mac/i)
  ) {
    return true;
  } else {
    return false;
  }
}

let getFirebaseMessagingToken;
let onMessageListener;

if (!iOS()) {
  const firebaseMessaging = getMessaging(firebaseApp);
  firebaseMessaging.onBackgroundMessageHandler = (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
    };

    self.registration.showNotification(notificationTitle, notificationOptions);
  };

  getFirebaseMessagingToken = async (setMessagingTokenFound, setMessagingToken) => {
    try {
      const currentToken = await getToken(firebaseMessaging, {
        vapidKey: 'BJj-r3p2DEwCg4j5-fKoHVO9amXb4CbIVpcVTAnmdZ6jOiEbKIT8sAfwrZM7iQ_hVCYXBAj_LcM5Z7KNgzkGVEM',
      });
      if (currentToken) {
        setMessagingTokenFound(true);
        setMessagingToken(currentToken);
        fetch(
          `${process.env.React_App_Base_URL}/api/User/update-web-notification-key?NorificationKey=${currentToken}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: user?.Token,
            },
          },
        );
      } else {
        console.log('cannot get token');
        setMessagingTokenFound(false);
        setMessagingToken('');
      }
    } catch (err_1) {
      console.log(err_1);
    }
  };

  // onMessageListener = () =>
  //   new Promise((resolve) => {
  //     onMessage(firebaseMessaging, (payload) => {
  //       console.log('onMessage');
  //       resolve(payload);
  //     });
  //   });
  onMessageListener = (callback) => {
    onMessage(firebaseMessaging, (payload) => {
      console.log('Message received:', payload);
      callback(payload); // Trigger the provided callback with the message payload
    });
  };
}

export { auth, getFirebaseMessagingToken, onMessageListener };
