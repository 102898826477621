import { Alert, AlertTitle, Box, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { FaCheck } from 'react-icons/fa';
import {
  IoAlertCircleOutline,
  IoCheckmarkOutline,
  IoCloseCircleOutline,
  IoInformationCircleOutline,
} from 'react-icons/io5';
import { IoIosCheckmarkCircleOutline, IoMdClose, IoMdCloseCircleOutline } from 'react-icons/io';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';

const CustomNotification = ({ message, type, status }) => {
  const [open, setOpen] = useState(status);
  const { hideSnackbar } = useGlobal();

  useEffect(() => {
    setOpen(status);
  }, [status]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    hideSnackbar();
    setOpen(false);
  };

  return (
    <Box sx={{ zIndex: 5000 }}>
      {type == 'success' && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          sx={{ mt: 8 }}
        >
          <Alert
            icon={<IoIosCheckmarkCircleOutline fontSize='inherit' />}
            action={<IoMdClose onClick={handleClose} style={{ width: '1.2rem', height: '1.2rem', marginTop: '4px' }} />}
            severity={type}
            variant='filled'
            sx={{
              width: '100%',
              // background: '#EDF7ED',
              color: '#fff',
            }}
          >
            <AlertTitle>Success</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      )}
      {type == 'error' && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          sx={{ mt: 8 }}
        >
          <Alert
            icon={<IoCloseCircleOutline fontSize='inherit' />}
            action={<IoMdClose onClick={handleClose} style={{ width: '1.2rem', height: '1.2rem', marginTop: '4px' }} />}
            severity={type}
            variant='filled'
            sx={{
              width: '100%',
              // background: '#00A76F',
              color: '#fff',
            }}
          >
            <AlertTitle>Error</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      )}
      {type == 'warning' && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          sx={{ mt: 8 }}
        >
          <Alert
            icon={<IoAlertCircleOutline fontSize='inherit' />}
            action={<IoMdClose onClick={handleClose} style={{ width: '1.2rem', height: '1.2rem', marginTop: '4px' }} />}
            severity={type}
            variant='filled'
            sx={{
              width: '100%',
              background: '#ED6C02',
              color: '#fff',
            }}
          >
            <AlertTitle>Warning</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      )}
      {type == 'info' && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          sx={{ mt: 8 }}
        >
          <Alert
            icon={<IoInformationCircleOutline fontSize='inherit' />}
            action={<IoMdClose onClick={handleClose} style={{ width: '1.2rem', height: '1.2rem', marginTop: '4px' }} />}
            severity={type}
            variant='filled'
            sx={{
              width: '100%',
              // background: '#00A76F',
              color: '#fff',
            }}
          >
            <AlertTitle>Info</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

// Add prop validation using PropTypes
CustomNotification.propTypes = {
  message: PropTypes.string.isRequired, // message prop is required and must be a string
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired, // type prop is required and must be one of these values
  status: PropTypes.bool.isRequired, // status prop is required and must be a boolean
};

export default CustomNotification;
