import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
const PermissionsContext = createContext(null);

export const useUserPermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState(null);
  const [isPermissionLoading, setPermissionIsLoading] = useState(true);

  return (
    <PermissionsContext.Provider
      value={{
        userPermissions,
        setUserPermissions,
        isPermissionLoading,
        setPermissionIsLoading,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
// Add PropTypes validation for children
PermissionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
