import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useInfoViewActionsContext } from '@crema/context/AppContextProvider/InfoViewContextProvider';

const AuthContext = createContext();
const AuthActionsContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const useAuthActions = () => useContext(AuthActionsContext);

const CustomAuthProvider = ({ children }) => {
  const { fetchStart, fetchSuccess, fetchError } = useInfoViewActionsContext();

  const [userData, setUSerData] = useState({
    user: null,
    isLoading: true,
    isAuthenticated: true,
  });
  useEffect(() => {
    fetchStart();
    if (localStorage.getItem('userInfo') != null) {
      const userData = localStorage.getItem('userInfo');
      const currentUserData = JSON.parse(userData);

      if (userData.Token != null) {
        setUSerData({
          user: currentUserData,
          isAuthenticated: true,
          isLoading: false,
        });
        fetchSuccess();
      }
    } else {
      setUSerData({
        user: null,
        isAuthenticated: false,
        isLoading: false,
      });
      fetchSuccess();
    }
  }, []);

  const logInWithCustomEmailAndPassword = async (user) => {
    fetchStart();
    try {
      // const { user } = await signInWithEmailAndPassword(auth, email, password);
      setUSerData({ user, isAuthenticated: true, isLoading: false });

      localStorage.setItem('userInfo', JSON.stringify(user));
      window.location.reload();
      fetchSuccess();
    } catch (error) {
      setUSerData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
      fetchError(error.message);
    }
  };

  const customLogout = async () => {
    localStorage.removeItem('userInfo');
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{
        ...userData,
      }}
    >
      <AuthActionsContext.Provider
        value={{
          logInWithCustomEmailAndPassword,
          customLogout,
        }}
      >
        {children}
      </AuthActionsContext.Provider>
    </AuthContext.Provider>
  );
};
export default CustomAuthProvider;

CustomAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fetchStart: PropTypes.func,
  fetchSuccess: PropTypes.func,
  fetchError: PropTypes.func,
};
