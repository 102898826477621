import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';

const CustomImageEditor = ({ image, updateImage, index, setDialogImagedEditor }) => {
  const maxDimension = 1920;

  async function dataURItoBlob(dataURI) {
    // First, get the original image size for comparison
    const response = await fetch(image?.original || image?.url);
    const originalBlob = await response.blob();
    const originalSize = originalBlob.size;

    // Process the edited image
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');

        // Use the actual cropped dimensions from the image
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');

        // Disable smoothing to prevent quality loss
        ctx.imageSmoothingEnabled = false;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Try different quality settings if needed
        const tryCompress = (quality = 1) => {
          canvas.toBlob(
            (blob) => {
              // If the new blob is larger than original, try with lower quality
              if (blob.size > originalSize && quality > 0.7) {
                tryCompress(quality - 0.1);
              } else {
                resolve(blob);
              }
            },
            'image/jpeg',
            quality,
          );
        };

        tryCompress();
      };
      img.src = dataURI;
    });
  }

  useEffect(() => {
    window.addEventListener('unhandledrejection', (event) => {
      console.warn('Unhandled promise rejection:', event.promise, 'reason:', event);
    });
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', zIndex: '1' }}>
      <FilerobotImageEditor
        source={image?.original || image?.url}
        onModify={(designState) => console.log('modify', designState)}
        onSave={async (editedImageObject, designState) => {
          const blob = await dataURItoBlob(editedImageObject.imageBase64);
          updateImage(blob, index);
        }}
        closeAfterSave={true}
        showBackButton={true}
        disableSaveIfNoChanges={true}
        avoidChangesNotSavedAlertOnLeave={true}
        onBeforeSave={() => false}
        onClose={() =>
          setDialogImagedEditor({
            open: false,
            photo: null,
            index: null,
          })
        }
        useBackendTranslations={false}
        annotationsCommon={{
          fill: '#ff0000',
        }}
        Rotate={{ angle: 90, componentType: 'buttons' }}
        tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
        defaultToolId={TOOLS?.CROP}
        Crop={{
          ratio: 'custom',
          presetsItems: [],
          autoResize: false,
          minWidth: 50,
          minHeight: 50,
          maxWidth: maxDimension,
          maxHeight: maxDimension,
          noPresets: true,
          preserveQuality: true,
        }}
        savingPixelRatio={1}
        previewPixelRatio={1}
        defaultSavedImageType='jpeg'
        defaultSavedImageQuality={1}
      />
    </div>
  );
};

CustomImageEditor.propTypes = {
  image: PropTypes.object,
  index: PropTypes.number,
  updateImage: PropTypes.func,
  setDialogImagedEditor: PropTypes.func,
};

export default CustomImageEditor;
