import { useAuthUser } from '@crema/hooks/AuthHooks';
import { HubConnectionBuilder } from '@microsoft/signalr';

const SystemHubConnection = () => {
  const { user } = useAuthUser();
  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.React_App_Base_URL}/api/Hubs/SystemHub?access_token=${user?.Token}`, {
      // headers: {
      //   Authorization: user.Token, // Use your custom header name
      // },
    })
    .withAutomaticReconnect([0, 2000, 2000, 2000, 5000, 5000, 5000, 5000, 10000]) // Retry delays in milliseconds: immediately, after 2s, 5s, and 10s

    .build();

  connection.onreconnecting((error) => {
    console.log('Connection lost, attempting to reconnect...', error);
  });

  connection.onreconnected((connectionId) => {
    console.log('Connection reestablished. Connected with connectionId:', connectionId);
  });

  connection.onclose((error) => {
    console.log('Connection closed. Attempting to reconnect...', error);
  });

  const startConnection = () => {
    connection
      .start()
      .then(() => {
        console.log('Connection started successfully.');
      })
      .catch((err) => {
        console.log('Connection failed: ', err.toString());
        // Retry connection after a delay if it fails
        setTimeout(() => startConnection(), 5000);
      });
  };

  startConnection();

  return connection;
};

export default SystemHubConnection;
