import React from 'react';
import './initialloader.css';

const InitialLoader = () => {
  return (
    <div className='initial-loader'>
      <div className='loader-spin'>
        <span className='crema-dot crema-dot-spin'>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  );
};

export default InitialLoader;
